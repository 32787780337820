// Override (simple)
// --------
//
//  Table of Contents:
//
//	1. Body
//	2. Content
//		2.1 Buttons
//		2.? Callouts
//		2.3	Content Management page
//		2.3	Forms
//		2.5 full.width
//		2.6 Home page
//			2.6.1	bizazz
//			2.6.2	.middle
//			2.6.3	.wegrow, inc .wombat and .grass
//		2.7 Portfolio page
//		2.8 Tables
//		2.9 Text Styles
//	3. Fonts
//	4. Header
//	5. Menu
//	6. Footer
//	7. Menus, inc sticky, top amd title bar
//
//	---- end table of contents ---

//	1. Body
// -----------------------------------------------------------------------1
body {
	font-family: "Open Sans", sans-serif;
}

//	2. Content
// -----------------------------------------------------------------------2

.content {
	h1, h3, h5 {
		color: getColour(colour1, text);
	}
	h2, h4 {
		color: getColour(colour3, text);
	}
	.callout {
		&.feature {
			padding: 0;
			h1,h2,h3,h4,h5,h6,p {
				padding: 0.5rem 1rem 0.5rem 1rem;
				margin-bottom: 0;
			}
		}
		.title {
			background: getColour(colour3, text);
			color: #ffffff;
		}
	}
	a:hover .callout {
		.title {
			background: getColour(colour3, lighter);
		}
	}

}

//	4. Header
// -----------------------------------------------------------------------2
//old override
#header>div {
	margin: 0 -15px 0 -15px;
	position: relative;
	.foreground {
		position: relative;
		z-index: 2;
	}
	.background {
		position: absolute;
		z-index: 1;
		top: 0px;
		left: 0;
	}
}

//	5. Menu
// -----------------------------------------------------------------------2

#menu {
	position: relative;
	z-index: 3;
	margin-top: -1px;
	bottom: 0px;
	left: 0;
	width: 100%;
	border-bottom: solid 4px getColour(colour1, text);
	.menu {
		li+li {
				border-left: solid 1px #fff;
		}
		a {
			font-weight: bold;
		}
		.menu {
			li {
				border-left: none;
			}
			a {
				font-weight: normal;
				width: 200px;
			}
		}
	}
	@include breakpoint(medium) {
		.medium-align-center {
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
		}
		.menu.medium-align-center {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
		}
	}
}
//	6. Footer
// -----------------------------------------------------------------------2

#footer {
	margin: 0 -15px 0 -15px;
	background-color: getColour(colour1, text);
	color: #fff;
	padding-top: 1rem;
	.contact {
		color: #fff;
		border: solid 1px #fff;
		background-color: transparent;
		&:hover {
		background: getColour(colour4, text);
		color: getColour(highlight, textBackground);
	}
	}
	.credit {
		color: #fff;
	}
}
